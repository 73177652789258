<template>
  <Main></Main>
</template>

<script>
import Main from './components/Main.vue';
export default {
  components: {
    Main
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Geologica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
  margin: 0 !important;
}
h1 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
h2 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
h3 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
h4 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
h5 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
h6 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
span {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
p {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
</style>
